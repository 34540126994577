/* eslint-disable vue/no-unused-components */
<template>
  <div class="view-blog-list-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Blogs</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right">
                      <!-- create router link -->
                      <!-- <router-link to="CreateUser" class="btn btn-success
                      btn-small">Create</router-link>-->
                    </div>
                    <div class="d-flex">
                      <div>
                        <h6>
                          List of Blogs
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h6>
                      </div>
                      <div class="ml-auto">
                        <form class="search-form mr-form">
                          <input
                            type="text "
                            class="form-control"
                            v-model="searchText"
                            @input="viewBlogList(1)"
                            placeholder="Search Here..."
                          />
                        </form>
                      </div>
                      <div>
                        <router-link to="/blog" append>
                          <b-button class="createBtn" variant="success">Create</b-button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <!-- <th>Hotel</th> -->
                          <th>Title</th>
                          <th>Date of Publish</th>
                          <th>
                            <b-dropdown :text="selected ? selected.text : 'Blog Type'">
                              <b-dropdown-item
                                :disabled="option.disabled"
                                @click="selectedBlogType(option)"
                                v-for="option in blogType"
                                :key="option.value"
                              >
                                <div class="text">{{ option.text }}</div>
                              </b-dropdown-item>
                            </b-dropdown>
                          </th>
                          <th>Status</th>
                          <th colspan="2">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(user, index) in users" v-bind:key="user._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>
                          <!-- <td>
                            <div>{{ user.hotel }}</div>
                          </td> -->
                          <td>
                            <div>{{ user.title }}</div>
                          </td>

                          <td>
                            <div>
                              <!-- {{moment(user.blogDate.format('MM DD YYYY'))}} -->
                              {{ user.blogDate.slice(0, 10) }}
                            </div>
                          </td>
                          <td>
                            <div>{{ user.blogType }}</div>
                          </td>
                          <td>
                            <div>{{ user.status }}</div>
                          </td>
                          <!-- v-repeat -->

                          <td>
                            <div class="d-flex">
                              <div class="ml-2 cursor-pointer">
                                <router-link
                                  class="btn btn-primary"
                                  :to="{ name: 'editBlog', params: { id: user._id } }"
                                  append
                                >
                                  <!-- <font-awesome-icon :icon="['fas', 'edit']" /> -->
                                  <b-icon-pencil></b-icon-pencil>
                                </router-link>
                              </div>
                              <!-- <div class="ml-2">
                                <deletemodal :mymodal="user"></deletemodal>
                              </div>-->
                              <div class="deletedModal">
                                <b-button
                                  variant="danger"
                                  v-b-modal.delete-modal
                                  @click="deleteBlogById(user)"
                                >
                                  <b-icon-trash></b-icon-trash>
                                </b-button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <div>
                          <deletemodal
                            :mymodal="blogData"
                            v-on:conformModal="deleteBlogs"
                          ></deletemodal>
                        </div>
                        <!-- end v-repeat -->
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="users.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="users.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewBlogList(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import deletemodal from "@/components/deletemodal.vue";
import moment from "moment";

export default {
  components: {
    headerCustom,
    deletemodal
  },
  data() {
    return {
      id: "",
      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      users: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: "",
      email: "",
      blogData: {},
      selected: null,
      blogType: [
        {
          text: "All",
          value: "All"
        },
        {
          text: "Featured",
          value: "featured"
        },
        {
          text: "Popular",
          value: "popular"
        },
        {
          text: "Archive",
          value: "archive"
        }
      ]
    };
  },
  methods: {
    viewBlogList(pageNo) {
      this.users = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      service.viewBlogs(this.formData, data => {
        if (data.status === 200) {
          this.users = data.data.result;
          this.totalCount = data.data.count;
        } else {
        }
      });
    },
    deleteBlogById(user) {
      this.blogData = user;
    },
    deleteBlogs(blog) {
      service.deleteBlogs(blog._id, result => {
        if (result.code == 200) {
          this.$toaster.success("Blogs Deleted");
          this.viewBlogList(1);
        } else {
          this.$toaster.errors("Something went wrong");
        }
      });
    },
    selectedBlogType(option) {
      this.selected = option;
      if (option.text == "All") {
        this.formData.type = option.value;
        this.formData.blogType = "";
      } else {
        this.formData.blogType = option.value;
        this.formData.type = "";
      }
      this.viewBlogList(this.page);
    }
  },
  created() {
    this.viewBlogList(this.page);
  }
};
</script>

<style lang="scss">
.view-blog-list-section {
  .text {
    color: black;
  }
  .btn-secondary {
    background: transparent;
    border-color: transparent;
    color: black;
    box-shadow: none;
    font-weight: 700;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border-color: transparent;
      color: black;
      box-shadow: none;
    }
  }
  .table .btn {
    padding: 0px 6px;
  }
}
</style>
